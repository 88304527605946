import React from 'react'
import contactcss from './Contact.css';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import { CardActionArea,Typography } from '@mui/material';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
export default function Contact() {
  return (
      <Grid container columnSpacing={{ xs:1,sm:2,md:4 }} style={{ display:'flex',justifyContent:'center' }}>
            <Card sx={{ width:'80%',borderRadius:'15px' }} style={{ backgroundColor:'#132F4D', marginTop: '-130px' }}>
                <CardActionArea>
                    <div style={{ textAlign:'center'}}>           
                            <div style={{ display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'50px' }}> 
                                <Typography className='mainHeadContact' >
                                <span className="whiteContact"> <span className="capitalContact">N</span>ot sure what your </span> <span className="blueContact">business  </span><span className="whiteContact">actually needs? <span className="capitalContact">G</span>et in touch!  </span>                                
                                </Typography>
                               
                            </div>
                            <div style={{ display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'20px' }}> 
                               
                                <Typography className='mainHeadContactDesc'>
                                Other things can wait, but your business growth cannot! Consult one of our advisors for free. FlexForce makes sure to provide tailored solutions per the needs of your industry and business model, so why not start today?             
                                </Typography>
                            </div>
                        </div>
                        <div style={{ textAlign:'left', display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} className="contactForm">
                               
                                <Grid item xs={12} sm={12} md={6} className="contactMargin">
                                    <label className='labelSection'>Full Name</label>
                                    <input type='text' className='inputBox' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className="contactMargin">
                                    <label className='labelSection'>Email ID</label>
                                    <input type='text' className='inputBox' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className="contactSetInput contactMargin">
                                    <label className='labelSection'>Company Name</label>
                                    <input type='text' className='inputBox' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className="contactSetInput contactMargin">
                                    <label className='labelSection'>Contact Number</label>
                                    <input type='text' className='inputBox' />
                                </Grid>
                                <div className="contactDiv">           
                                    <Typography >
                                    <span className="contactButton"> Submit</span> 
                                    </Typography>
                                </div>
                            </Grid>
                        </div>
                       
                </CardActionArea>
            </Card>
      </Grid>
  )
}
