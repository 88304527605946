//import liraries
import React, { Component, useEffect } from 'react';
import './SitesList.css';
import urjaIMac from '../assets/images/iMac_Pro_Front_View_Mockup.png';
import leagueUnoiMac from '../assets/images/iMac_pro_league_Uno.png';
import agreeMarketiMac from '../assets/images/iMac_Pro_Front_agremarket.png';
import paytamashaiMac from '../assets/images/iMac_Pro_Front_paytamasha.png';


// create a component
const SiteList = () => {

    useEffect(()=>{
        document.getElementById('item-1').click();
    },[])

    return (
        <div className='ListMainContainer'>
            <h2 className='ListHeading'>our <span> client </span>  </h2>
            <h2 className='ListDescription'>At FlexForce, our success is intertwined with the success of our valued clients.  </h2>
            <div class="container">
                <input type="radio" name="slider" id="item-1" />
                <input type="radio" name="slider" id="item-2" />
                <input type="radio" name="slider" id="item-3" />
                {/* <input type="radio" name="slider" id="item-4" /> */}
            <div class="cards">
                <label class="card" for="item-1" id="song-1">
                <img className='CardImage' src={urjaIMac} alt="song" />
                </label>
                <label class="card" for="item-2" id="song-2">
                <img className='CardImage' src={leagueUnoiMac} alt="song" />
                </label>
                <label class="card" for="item-3" id="song-3">
                <img className='CardImage' src={agreeMarketiMac} alt="song" />
                </label>
                {/* <label class="card" for="item-4" id="song-4">
                <img className='CardImage' src={paytamashaiMac} alt="song" />
                </label> */}
            </div>
            {/* <div class="player">
                <div class="upper-part">
                <div class="play-icon">
                    <svg width="20" height="20" fill="#2992dc" stroke="#2992dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-play" viewBox="0 0 24 24">
                    <defs/>
                    <path d="M5 3l14 9-14 9V3z"/>
                    </svg>
                </div>
                <div class="info-area" id="test">
                    <label class="song-info" id="song-info-1">
                    <div class="title">Bunker</div>
                    <div class="sub-line">
                        <div class="subtitle">Balthazar</div>
                        <div class="time">4.05</div>
                    </div>
                    </label>
                    <label class="song-info" id="song-info-2">
                    <div class="title">Words Remain</div>
                    <div class="sub-line">
                        <div class="subtitle">Moderator</div>
                        <div class="time">4.05</div>
                    </div>
                    </label>
                    <label class="song-info" id="song-info-3">
                    <div class="title">Falling Out</div>
                    <div class="sub-line">
                        <div class="subtitle">Otzeki</div>
                        <div class="time">4.05</div>
                    </div>
                    </label>
                </div>
                </div>
                <div class="progress-bar">
                <span class="progress"></span>
                </div>
            </div> */}
            

            </div>
            <div className='ownerDetailSection'>
            <h2 className='ownnerHeading'>- Rajiv Bang, founder </h2>
            <h2 className='ownnerDescription'>FlexForce has been an invaluable partner in our journey. Their expertise in made a significant impact on our growth. We highly recommend their services. </h2>
            </div>
        </div>
    );
};

// define your styles
const styles = {
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
};

//make this component available to the app
export default SiteList;
