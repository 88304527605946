import React from 'react';
import { Button, Grid } from '@material-ui/core';
import styles from './Hoversection.css';
import { Typography } from '@mui/material';
import img1 from '../assets/images/growimage1.png';
import img2 from '../assets/images/growimage2.png';
import img3 from '../assets/images/growimage3.png';
import noiceTexture from '../assets/images/NoiseTexture.svg';
import videobg from '../assets/video/top.mp4';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import back2 from '../assets/images/back2.png';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#0F2C4A' : '#0F2C4A',
    textAlign: 'center',
    padding:'0px',
    marginTop:'0px',
    borderRadius: '80px',
    // color: theme.palette.text.secondary,
  }));
export default function Hoversection() {
  return (
    <>
            <Grid container  columnSpacing={{ xs: 1, sm: 2, md: 4 }} style={{display:'flex',justifyContent:'center',padding:'49px',backgroundColor:'#0F2C4A',marginTop:'20px', backgroundImage: `url(${noiceTexture})`} }>
                <Grid item xs={12} sm={12} md={12} style={{ display:'flex',justifyContent:'center', }}>
                    <div style={{ textAlign:'center'}}>           
                        <div style={{ display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'80px' }}> 
                            <Typography className='mainHeadHover'>
                                <span className="blueContent">Grow your business tenfold </span>
                                <span className="whiteContent">with the support of elite industry experts</span>
                            </Typography>
                        </div>
                        <div style={{ display:'flex',flexDirection:'row',justifyContent:'center' }}> 
                            <Typography className="mainHead1">
                            <span className="blueContent1">Power of expertise at your fingertips.</span> Access our network of elite advisors, industry leaders, consultants, and C-suite executives to accelerate your business growth.</Typography>
                        </div>
                    </div>
                </Grid>
                <Box sx={{ width: '85%',marginTop:'80px'}} style={{ backgroundImage: `url(${noiceTexture})` }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={4} style={{ backgroundColor:'#0F2C4A',backgroundImage: `url(${noiceTexture})` ,marginBottom:'20px',textAlign:'center'  }}>
                                <div className="flip">
                                <Item><img src={img1} alt="alternative-text" className="hoverImg left front" /></Item>
                                <h2 className="imageContent">Advisors</h2>
                                    
                                    <div className="back" style={{ backgroundImage: `url(${back2})` }}>
                                    <h2>C-suite Executives</h2>
                                    <p>be it CTOs, CMOs, or CFOs - find the right fit per your needs and build impactful tech & innovation strategies, marketing campaigns, and brand strategies for your business.</p>
                                    </div>
                                </div>
                          
                        </Grid>
                            <Grid item xs={12} sm={12} md={4} style={{ backgroundColor:'#0F2C4A',backgroundImage: `url(${noiceTexture})`,marginBottom:'20px',textAlign:'center'  }}>
                                <div className="flip">
                                    <Item>
                                    <img src={img2} alt="alternative-text" className="hoverImg front" />
                                    <h2 className="imageContent">C-suite Executives</h2>
                                    </Item>
                                    <div className="back" style={{ backgroundImage: `url(${back2})` }}>
                                    <h2>C-suite Executives</h2>
                                    <p>be it CTOs, CMOs, or CFOs - find the right fit per your needs and build impactful tech & innovation strategies, marketing campaigns, and brand strategies for your business.</p>
                                    </div>
                                </div>
                            </Grid>

                        <Grid item xs={12} sm={12} md={4} style={{ backgroundColor:'#0F2C4A',backgroundImage: `url(${noiceTexture})`,marginBottom:'20px',textAlign:'center'  }}>
                        <div className="flip">
                                    <Item>
                                    <img src={img3} alt="alternative-text" className="hoverImg front right" />
                                    </Item>
                                    <h2 className="imageContent">Consultants</h2>
                                    <div className="back" style={{ backgroundImage: `url(${back2})` }}>
                                    <h2>C-suite Executives</h2>
                                    <p>be it CTOs, CMOs, or CFOs - find the right fit per your needs and build impactful tech & innovation strategies, marketing campaigns, and brand strategies for your business.</p>
                                    </div>
                                </div>
                        </Grid>
                    </Grid>
                </Box>
                <Grid item xs={12} sm={12} md={12} style={{ display:'flex',justifyContent:'center', }}>
                    <div style={{ textAlign:'center' ,padding:'50px'}}>           
                           <Typography className="buttonHoversection">
                           <span className="contentButton"> Get support now</span> 
                           </Typography>
                    </div>
                </Grid>
            </Grid>
    </>
  )
}
