import React from 'react';
import { Button, Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import { CardActionArea,Typography } from '@mui/material';
import PoweredByFavcyCss from './PoweredByFavcy.css';
import poweredimg  from '../../assets/images/Favcy_Logo_FavcyVB_Orange-1.png';
import videobg from '../../assets/video/2462249_Background_Business_Fashion_1920x1080.mov';
export default function PoweredByFavcy() {
  return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
             style={{padding:'0px',display:'flex',flexDirection:'row',justifyContent:'center' }}>
                <Card sx={{ width:'80%',borderRadius:'15px' }} className="cardSection">
                        <Grid item xs={12} sm={12} md={6} style={{ display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'27px'}}>
                            <Typography className="poweredbyContent">
                                powered by
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <img src={poweredimg} className="poweredimg" />
                        </Grid>
                </Card>
                    <video className="backgroundvideoPowered" autoPlay loop muted>
                    <source src={videobg} type="video/mp4" />
                    </video>
            </Grid>
        </>
  )
}
