import React from 'react'
import { Grid } from '@material-ui/core';
import styles from './Topsection.css';
import { Typography } from '@mui/material';
import fullimg from '../assets/images/fullimg.png';
import videobg from '../assets/video/top.mp4';
export default function Topsection() {
  return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} style={{padding:'10px'} }>
            <Grid item xs={12} sm={12} md={12} style={{ display:'flex',justifyContent:'center', }}>
              <div className='topSectionDiv'>
                <img src={fullimg} alt="alternative-text" className="fullLogo" />
                
                <div style={{ display:'flex',flexDirection:'row',paddingRight:'5px',justifyContent:'center',marginTop:'20px' }}> 
                <Typography className='mainHeadTop'>
                    <span className="whiteContent">Discover </span>
                    <span className="blueContent">talented folks </span>
                    <span className="whiteContent">& </span>
                    <span className="blueContent">elite advisors </span>
                    
                    <span className="whiteContent">for your business</span>
                </Typography>
                </div>
                <div style={{ display:'flex',flexDirection:'row',paddingRight:'5px',justifyContent:'center',marginTop:'20px' }}> 
                <Typography className="mainHead1">FlexForce provides access to a pool of talented resources and elite
                  advisors, consultants, and C-suite executives for strategic guidance.</Typography>
                  </div>
            <video className="backgroundvideo" autoPlay loop muted>
              <source src={videobg} type="video/mp4" />
            </video>
              </div>
              </Grid>
            </Grid>
        </>
  )
}
