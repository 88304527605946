import logo from './logo.svg';
import './App.css';
import { Grid } from '@material-ui/core'
import Nav from './Nav';
import Footer from './Footer';
import Topsection from './section/Topsection';
import Slider from './section/Slider';
import Hoversection from './section/Hoversection';
import Your from './section/Your';
import { useEffect, useRef, useState, useMemo } from "react";
import SiteList from './section/SitesList';
import ProgressSteps from './section/progressSteps/ProgressSteps';
import Contact from './section/Contact';
import PoweredByFavcy from './section/poweredbyFavcy/PoweredByFavcy';


function App() {

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} id="main" >
        <Nav />
        <Topsection />
        <Slider />
        <Hoversection />
        <ProgressSteps />
        <Contact />
        <Your />
        <SiteList />
        <PoweredByFavcy />
        <Footer />
    </Grid>
  );
}

export default App;
