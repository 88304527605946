import { Grid } from '@material-ui/core';
import fullLogo from './assets/images/footerLogo.png';
import fbLogo   from './assets/images/fb.png';
import twitter   from './assets/images/twitter.png';
import insta   from './assets/images/insta.png';
import linkedin   from './assets/images/linkedin.png';
import React from 'react'
import Footercss from './Footer.css';

import { Typography } from '@mui/material';

export default function Footer() {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} className="footerContainer">
      <Grid item xs={12} sm={6} md={4} className="gridDiv">
      <div className="grid1">
        <img src={fullLogo} alt="alternative-text" className="footerfullLogo" />
        </div>
        <div className="footerFirstDiv grid1" > 
            <img src={fbLogo} alt="alternative-text" className="socialLogo" />
            <img src={linkedin} alt="alternative-text" className="socialLogo" />
            <img src={twitter} alt="alternative-text" className="socialLogo" />
            <img src={insta} alt="alternative-text" className="socialLogo" />
        </div>
        <div className="copyGrid1">
        <Typography className="copyRight">copyright 2023 all rights reserved</Typography>
      </div>
      </Grid>
      <Grid item xs={4} sm={6} md={2} className="grid2">
        <div style={{ display:'flex',flexDirection:'column',justifyContent:'center'}}>
          <Typography className="TypographyMain" >quick links</Typography>
          <Typography className="Typography" >About </Typography>
          <Typography className="Typography" >Our Services </Typography>
          <Typography className="Typography" >Blogs </Typography>
          <Typography className="Typography" >Testimonial</Typography>
        </div>
      </Grid>
      <Grid item xs={8} sm={6} md={3} className="grid3">
        <div style={{ display:'flex',flexDirection:'column',justifyContent:'center'}}>
          <Typography className="TypographyMain" >important info</Typography>
          <Typography className="Typography" >T&C </Typography>
          <Typography className="Typography">privacy policy </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className='grid4'>
        <div style={{ display:'flex',flexDirection:'column',justifyContent:'center'}}>
          <Typography className="TypographyMain" >get in touch</Typography>
          <Typography className="Typography" >+91 99999 99999 </Typography>
          <Typography className="Typography" >contact@flexforce.com</Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className="copyGrid">
        <div style={{ display:'flex',flexDirection:'column',justifyContent:'left'}}>
        <Typography className="copyRight">copyright 2023 all rights reserved</Typography>
      </div>
      </Grid>
    </Grid>
  
  )
}

