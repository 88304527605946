import React, { Component } from 'react';
import './ProgressSteps.css';
import { Grid } from '@mui/material';
import accessPoolImage from '../../assets/images/accessPool.svg';
import cutShotImage from '../../assets/images/cutShot.svg';
import getAFlexibleImage from '../../assets/images/getAflexible.svg';
import getHelpImage from '../../assets/images/getHelp.svg';
import takeRightImage from '../../assets/images/takeRight.svg';
import optimizeImage from '../../assets/images/optimize.svg';

const ProgressSteps =() =>{

    return(
        <>
        <div className='mainProgressStepsContainer'>
            <h2 className='stepHeading'>Get what you expect, Nothing but <span> the best </span>  </h2>
            <Grid container flex={1} flexDirection={'column'} >
                    <ul className="events">
                    <li>
                            <h3 className='MainHeading'>FlexFolks</h3>
                            <h3 className='MainHeading'>EliteHours</h3>
                        </li>
                    <li>
                        <div>
                            <img className='progressImage' src = {accessPoolImage} alt="My Happy SVG"/>
                            <h3 className='leftDivText progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </div>
                        <span>
                            <img className='progressImage' src = {getHelpImage} alt="My Happy SVG"/>
                            <h3 className='progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                    <li>
                        <div>
                            <img className='progressImage' src = {cutShotImage} alt="My Happy SVG"/>
                            <h3 className='leftDivText progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </div>
                        <span>
                            <img className='progressImage' src = {takeRightImage} alt="My Happy SVG"/>
                            <h3 className='progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                    <li>
                        <div>
                            <img className='progressImage' src = {getAFlexibleImage} alt="My Happy SVG"/>
                            <h3 className='leftDivText progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </div>
                        <span>
                            <img className='progressImage' src = {optimizeImage} alt="My Happy SVG"/>
                            <h3 className='progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                        
                    </ul>
            </Grid>
        </div>
        <div className='mobileProgressStepsContainer'>
            <h2 className='stepHeading'>Get what you expect, Nothing but <span> the best </span>  </h2>
            <Grid container flex={1} flexDirection={'column'} >
                <ul className="events">
                    <li>
                        <h3 className='MobileHeading'>FlexFolks</h3>
                    </li>
                    <li>
                        <span>
                            <img className='progressImage' src = {accessPoolImage} alt="My Happy SVG"/>
                             <h3 className='leftDivText progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                    <li>
                        <span>
                            <img className='progressImage' src = {cutShotImage} alt="My Happy SVG"/>
                             <h3 className='leftDivText progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                    <li>
                        <span>
                             <img className='progressImage' src = {getAFlexibleImage} alt="My Happy SVG"/>
                             <h3 className='leftDivText progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                </ul>
                <ul className="events">
                    <li>
                        <h3 className='MobileHeading'>EliteHours</h3>
                    </li>
                    <li>
                        <span>
                            <img className='progressImage' src = {getHelpImage} alt="My Happy SVG"/>
                             <h3 className='progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                    <li>
                        <span>
                            <img className='progressImage' src = {takeRightImage} alt="My Happy SVG"/>
                             <h3 className='progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                    <li>
                        <span>
                             <img className='progressImage' src = {optimizeImage} alt="My Happy SVG"/>
                             <h3 className='progressStepText'>Access a pool of diversified and talented resources pan India easily</h3>
                        </span>
                    </li>
                </ul>
            </Grid>
            
        </div>
        </>

    )
}

export default ProgressSteps;