import { useEffect, useRef, useState } from "react";
import styles from "./Slider.css";
import video1 from "../assets/video/516041_Keyboard_Laptop_Typing_3840x2160.mp4";
import video2 from "../assets/video/999355_Business_Partner_Office_1920x1080.mp4";
import video3 from "../assets/video/1334897_Analysis_Analyst_Analytics_3840x2160.mp4";
import video4 from "../assets/video/1641277_Graphic_Design_Designer_Corporate_3840x2160.mp4";

const Slider =() => {

  const [active, setActive] = useState(0);

  const wheelTimeout = useRef()

  const wheelEvent = (event) =>{

    clearTimeout(wheelTimeout.current)

    // flag indicating to lock page scrolling (setTimeout returns a number)
    wheelTimeout.current = setTimeout(() => {
      wheelTimeout.current = false
    }, 2000)

    console.log("wheel_event--------------->",event.deltaY/100);

    if(wheelEvent.current){
        return
    }

    if ((event.deltaY/105000) > 0 ) {
        if(active <= 2){
            setActive(active+1);
            // activeIndex(active+1);
        }
    } else {
        if(active > 0){
            setActive(active-1);
            // activeIndex(active-1);
        }
    }
  }

//   useEffect(() => {
//     const cancelWheel = e => wheelTimeout.current && e.preventDefault()
//     document.body.addEventListener('wheel', cancelWheel, {passive:false})
//     return () => document.body.removeEventListener('wheel', cancelWheel)
// }, [])


const data = [
    {
        title: "Content",
        description: "Folks capable of flexing with just an idea and words, craft compelling narratives and engaging content for your target audience.",
        video_src: video1
    },
    {
        title: "Marketing",
        description: "Strategic geniuses who will help you drive growth on all platforms with flex-worthy campaigns, that will later be case studies for your competitors.",
        video_src: video2
    },
    {
        title: "UI/UX",
        description: "Prototype and wireframing experts who make sure to create seamless, user-friendly digital experiences for your target audience.",
        video_src: video3
    },
    {
        title: "Product Tech",
        description: "Techie folks who have the capability to leverage state-of-the-art tech solutions to keep you ahead of the curve.",
        video_src: video4
    },
];

  return (
    <>
    <div className="banner" id="bannerCard" onWheel={e=>wheelEvent(e)}>
     <div className="bannerInner" >
        <div className="groupParent" style={{transform : `translate3d(0px, ${-670 * active}px, 0px)`}}>
            {
                data.map((slide)=>{
                    return (
                        <div className="cardContent" >
                        <div className="getThatWorkDoneBrilliantlParent">
                            <b className="getThatWorkContainer">
                            <span>{`  get that `}</span>
                            <span className="talentedFolks">work done</span>
                            <span>, brilliantly</span>
                            </b>
                            <div className="accessBrightMindedTalented">
                            Access bright-minded talented folks, who are creative and absolute
                            experts at what they do. Amplify your businesses with their
                            specialized skillsets.
                            </div>
                        </div>
                        <div className="videoSection" >
                        <video className="contentVideo" autoPlay loop muted>
                        <source src={slide.video_src} type="video/mp4" />
                        </video>
                        </div>
                        <div className="groupContainer">
                            <div className="folksCapableOfFlexingWithParent">
                            <b className="contentTitle">{slide.title}</b>
                            <div className="folksCapableOf">
                                {slide.description}
                            </div>
                            </div>
                            <div className="flexForceBtnContainer">
                            <button className="flexForceBtn" onClick={() => {}}>Hire Flexfolks</button>
                            </div>
                        </div>
                            
                        </div>
                    )
                })
            }
            <ul className="dots">
                {data.map((_, index) => (
                <li key={index} className={index === active ? 'active' : ''}>
                    <button onClick={() => setActive(index)}>{index + 1}</button>
                </li>
                ))}
            </ul>
                    
        </div>
        
      </div>
      
    </div>
    <div className="bannerMobile" id="bannerCard" onWheel={e=>wheelEvent(e)}>
     <div className="bannerInner" >
     <div className="getThatWorkDoneBrilliantlParent">
                            <b className="getThatWorkContainer">
                            <span>{`  get that `}</span>
                            <span className="talentedFolks">work done</span>
                            <span>, brilliantly</span>
                            </b>
                            <div className="accessBrightMindedTalented">
                            Access bright-minded talented folks, who are creative and absolute
                            experts at what they do. Amplify your businesses with their
                            specialized skillsets.
                            </div>
                        </div>
        <div className="groupParent" style={{transform : `translate3d(0px, ${-670 * active}px, 0px)`}}>
            {
                data.map((slide)=>{
                    return (
                        <div className="cardContent" >
                        <div className="videoSection" >
                        <video className="contentVideo" autoPlay loop muted>
                        <source src={slide.video_src} type="video/mp4" />
                        </video>
                        </div>
                        <div className="groupContainer">
                            <div className="folksCapableOfFlexingWithParent">
                            <b className="contentTitle">{slide.title}</b>
                            <div className="folksCapableOf">
                                {slide.description}
                            </div>
                            </div>
                        </div>
                            
                        </div>
                    )
                })
            }
            <ul className="dots">
                {data.map((_, index) => (
                <li key={index} className={index === active ? 'active' : ''}>
                    <button onClick={() => setActive(index)}>{index + 1}</button>
                </li>
                ))}
            </ul>
                    
        </div>
        <div className="flexForceBtnContainer">
             <button className="flexForceBtn" onClick={() => {}}>Hire Flexfolks</button>
        </div>
        
      </div>
      
    </div>
    </>
  );
};

export default Slider;
