import React from 'react';
import Yourcss from './Your.css';
import { Button, Grid } from '@material-ui/core';
import { Typography } from '@mui/material';
import Texture from '../assets/images/Texture.png';
import Yourimg1 from '../assets/images/Yourimg1.png';
import youimg2  from '../assets/images/youimg2.png';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
   
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  }));

export default function Your() {
  return (
    <>
         <Grid container  columnSpacing={{ xs: 1, sm: 2, md: 4 }} style={{display:'flex',justifyContent:'center', backgroundImage: `url(${Texture})`} }>
                <Grid item xs={12} sm={12} md={12} style={{ display:'flex',justifyContent:'center', }}>
                    <div style={{ textAlign:'center'}}>           
                        <div style={{ display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'80px' }}> 
                            <Typography className='mainHead'>
                                <span className="whiteContent">Make yourself </span><span className="blueContent"> flex-worthy </span> <span className="whiteContent">today</span>
                               
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{padding:'80px'}}>
                            <Grid item xs={12} sm={6} md={6} >
                                <img src={Yourimg1} className="Yourimg1"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className='YourContent'> 
                                  <Typography className="YourmainHead1" style={{ width:'320px' }}>
                                  We're on the hunt for <span className="blueContent1">talented folks </span>to join our dynamic team. If you’re talented and want to flex hard, then apply now.
                                  </Typography>
                              </div>
                              <div className="YourContentButton">           
                                    <Typography >
                                    <span className="YourButton"> Apply Now</span> 
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} className="Grid3">
                              <div className='YourContent' > 
                                  <Typography className="YourmainHead1" style={{ width:'350px' }}>
                                  We are willing to learn as we grow. And we’d love to have more <span className="blueContent1">industry experts </span> in our team. If you’re experienced in your industry and want to help startups grow, join our team.
                                  </Typography>
                              </div>
                              <div className="YourContentButton">           
                                    <Typography >
                                    <span className="YourButton"> Join Our Team</span> 
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className="Grid4">
                                <img src={youimg2} className="Yourimg1"/>
                            </Grid>
                    </Grid>
         </Grid>
    </>
  )
}
